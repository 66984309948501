import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "./../../component/Loader";
import Breadcrumbs from "./../../component/breadcrumbs";
import Validate from "./../../component/Authvalidate";
import { Link } from "react-router-dom";
import RestService from "./RestService";
import CustomTable from "./CustomTable";

export default function Details() {
  let userParams = useParams();
  let order_id = userParams.order_id ? userParams.order_id : 0;
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  const mobile = userdata.mobile ? userdata.mobile : "";
  const web_token = userdata.web_token ? userdata.web_token : "";
  const [loading, setLoading] = useState(false);
  const [result, setresult] = useState(false);
  const [column, setColumn] = useState(false);
  const [pageStatus, setPageStatus] = useState(false);

  function refreshPage() {
    var d = new Date();
    var n = d.getTime();
    setColumn(n);
  }

  useEffect(() => {
    setLoading(true);
    RestService.getOrderdetails(
      function (res) {
        setLoading(false);
        const response = JSON.parse(res);
        setresult(response.data);
      },
      mobile,
      web_token,
      order_id,
      pageStatus
    );
  }, [mobile, web_token, order_id, pageStatus, column]);

  const getPageUpdatedStatus = (status) => {
    setPageStatus(!pageStatus);
  };

  return (
    <>
      <Validate />
      {loading ? <Loader /> : ""}
      <div className="bodycontainer">
        <div className="auto-space">
          <div className="row">
            <div className="col-lg-12">
              <div className="fixedAddButton">
                <Link className="btn btn-success mr-1" to="/order-dashboard">
                  <i className="fa fa-arrow-left"></i>
                </Link>
                <button onClick={refreshPage} className="btn btn-success ">
                  <i className="fa fa-refresh"></i>
                </button>
              </div>
              <Breadcrumbs page={`${order_id}`} />
              <div>
                <section className="contactCntr">
                  <div className="row">
                    <div className="col-sm-3">
                      <label className="order-span">Order ID: </label>
                      <label className="order-span-child">
                        {result?.result?.orderId}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">Sector Name:</label>
                      <label className="order-span-child">
                        {result?.result?.sectorName}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">Strategy Type:</label>
                      <label className="order-span-child">
                        {result?.result?.strategyType}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">Strategy Name:</label>
                      <label className="order-span-child">
                        {result?.result?.strategyName}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">Quantity</label>
                      <label className="order-span-child">
                        {result?.result?.quantity}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">No. of Stock</label>
                      <label className="order-span-child">
                        {result?.result?.noOfStock}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">Product</label>
                      <label className="order-span-child">
                        {result?.result?.product}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">Type</label>
                      <label className="order-span-child">
                        {result?.result?.signaltype}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">Profit Target</label>
                      <label className="order-span-child">
                        {result?.result?.profitTarget}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">Stop Loss</label>
                      <label className="order-span-child">
                        {result?.result?.stoploss}
                      </label>
                    </div>
                    <div className="col-sm-3">
                      <label className="order-span">Date</label>
                      <label className="order-span-child">
                        {result?.result?.date}
                      </label>
                    </div>
                    <div className="col-sm-12  text-right">
                      {result?.totalProfit && (
                        <button className="btn btn-sm btn-success">
                          <span className="mr-2">Total Ptofit: </span>
                          {result?.totalProfit}
                        </button>
                      )}

                      {result?.totalLoss && (
                        <button className="btn btn-sm btn-danger">
                          <span className="mr-2">Total Ptofit: </span>
                          {result?.totalLoss}
                        </button>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <CustomTable
                key={""}
                columndata={result?.column ? result?.column : ""}
                marketdata={result?.symbolList ? result?.symbolList : ""}
                redirectlink="/stocks/"
                pageSizeNumber="10"
                getPageUpdatedStatus={(e) => getPageUpdatedStatus(pageStatus)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
