import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import Loader from "./../../component/Loader";
import RestService from "./../../Restservice";
import { Link } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  space: {
    // height: 100,
  },
  appContent: {
    padding: 40,
    paddingBlock: 50,
  },
  setSelect: {
    width: "200px",
    display: "inline-block",
    marginRight: "10px",
    // zIndex: 999999
  },
  setxsSelect: {
    width: "150px",
    display: "inline-block",
  },
  bigFontSize: {
    fontSize: "1rem !important",
  },
  viewDetails: {
    backgroundColor: "green",
    float: "right",
    color: "#fff!important",
    padding: "2px 5px",
    fontSize: "10px",
  },
}));

export default function MobileSubscription({ modifiedArray, userStatus }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [popopen, setPopOpen] = React.useState(false);
  const [content, setcontent] = useState({});
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  const mobile = userdata.mobile ? userdata.mobile : "";
  const web_token = userdata.web_token ? userdata.web_token : "";
  const user_name = userdata.user_name ? userdata.user_name : "";
  // const parsedNumber = (number) => {
  //   return parseFloat(number).toFixed(2);
  // };

  const addToCart = (item, key) => {
    //const razorKey = "rzp_test_gW0MgSHv1yG8Kz";
    // old live key rzp_live_bM8jUzNNdr7733
    const razorKey = "rzp_live_CR1r4ofXXGMK4d";
    const price = parseFloat(item[key]);
    if (userStatus === 0) {
      setOpen(true);
    } else if (price > 0) {
      let options = {
        key: razorKey,
        //"key_secret": "zMWOjqJPHORfA1RkUsxHlv9z",
        amount: price * 100, // 2000 paise = INR 20, amount in paisa
        currency: "INR",
        name: "MnTree capital",
        description: "Purchase Description",
        image: "https://www.mntreecapital.com/assets/images/login_wide.png",
        handler: function (response) {
          let fdata = new FormData();
          item["selectedColumn"] = key;
          item["selectedAmount"] = price;
          fdata.append("mobile", mobile);
          fdata.append("web_token", web_token);
          fdata.append("cartData", JSON.stringify(item, null));
          fdata.append("orderResponse", JSON.stringify(response, null));
          //setLoading(true);
          RestService.updatePaymentDetail(function (res) {
            //history.push("/thank-you");
            setLoading(false);
          }, fdata);
        },
        prefill: {
          name: user_name,
          email: "",
        },
        notes: {
          address: "Noida",
        },
        theme: {
          color: "#F37254",
        },
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  const DialogBoxData = () => {
    return (
      <Dialog
        open={popopen}
        onClose={pophandleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={pophandleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {content?.name}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.appContent}>
          {content?.description ? ReactHtmlParser(content?.description) : ""}
        </div>
      </Dialog>
    );
  };
  const pophandleClose = () => {
    setPopOpen(false);
  };
  const viewDetails = (item) => {
    setcontent(item);
    setPopOpen(true);
  };
  return (
    <>
      {loading ? <Loader /> : ""}
      <DialogBoxData />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Authentication issue."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            It seems that either you are not loged-in or your session has been
            expired. Kindly login again to enjoy services.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleClose} className="btn-sm btn-success">
            Close
          </button>

          <Link to={"/login"} className="btn-sm btn-danger">
            Login
          </Link>
        </DialogActions>
      </Dialog>
      <div className="row">
        {modifiedArray?.map((item, key) => (
          <div key={key} className="col-lg-4 col-md-6 col-sm-6">
            <table className="table table-bordered">
              <tbody>
                <tr>
                  <td colSpan="3">
                    <h3 className="highlighttext">
                      <Link to="/premium-services" target="_blank">
                        {item.name}
                      </Link>
                      <button
                        className={classes.viewDetails + " btn mt-1"}
                        title="View Details"
                        onClick={(e) => viewDetails(item)}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td className={classes.bigFontSize}>1 Month</td>
                  <td className={`${classes.bigFontSize} text-center`}>
                    Rs.{item.onemonth}
                  </td>
                  <td className="text-center" style={{ width: "150px" }}>
                    <button
                      className="btn btn-success mr-2"
                      type="submit"
                      onClick={() => addToCart(item, "onemonth")}
                    >
                      Pay Now
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className={classes.bigFontSize}>3 Months</td>
                  <td className={`${classes.bigFontSize} text-center`}>
                    Rs.{item.threemonth}
                  </td>
                  <td className="text-center" style={{ width: "150px" }}>
                    <button
                      className="btn btn-success mr-2"
                      type="submit"
                      onClick={() => addToCart(item, "threemonth")}
                    >
                      Pay Now
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className={classes.bigFontSize}>6 Months</td>
                  <td className={`${classes.bigFontSize} text-center`}>
                    Rs.{item.sixmonth}
                  </td>
                  <td className="text-center" style={{ width: "150px" }}>
                    <button
                      className="btn btn-success mr-2"
                      type="submit"
                      onClick={() => addToCart(item, "sixmonth")}
                    >
                      Pay Now
                    </button>
                  </td>
                </tr>
                <tr>
                  <td className={classes.bigFontSize}>1 Year</td>
                  <td className={`${classes.bigFontSize} text-center`}>
                    Rs.{item.oneyear}
                  </td>
                  <td className="text-center" style={{ width: "150px" }}>
                    <button
                      className="btn btn-success mr-2"
                      type="submit"
                      onClick={() => addToCart(item, "oneyear")}
                    >
                      Pay Now
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </div>
    </>
  );
}
