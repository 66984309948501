import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "./../../component/breadcrumbs";
import Validate from "./../../component/Authvalidate";
import CustomTable from "./CustomTable";
import Loader from "../../component/Loader";
import RestService from "./RestService";
import OrderBreadcum from "./OrderBreadcum";
import Alert from "../../component/AlertComponent";

export default function PositionDashboard() {
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  const mobile = userdata.mobile ? userdata.mobile : "";
  const web_token = userdata.web_token ? userdata.web_token : "";
  const [loading, setLoading] = useState(false);
  const [result, setresult] = useState(false);
  const [column, setColumn] = useState(false);
  const [isShowErrorAlert, setShowErrorAlert] = useState(false);
  const pageName = "PositionDashboard";
  function refreshPage() {
    var d = new Date();
    var n = d.getTime();
    setColumn(n);
  }

  useEffect(() => {
    setLoading(true);
    RestService.getSimpleOrderRecord(
      function (res) {
        setLoading(false);
        const response = JSON.parse(res);

        setShowErrorAlert(false);
        if (response.status === 200) {
          if (response && response?.data.status === 1) {
            setresult(response.data);
          } else if (response?.data.status === 0) {
            setShowErrorAlert({
              showStatus: true,
              heading: `Oh snap! You got an error!`,
              message: `${response.data?.message}`,
              variant: "warning",
            });
          }
        } else {
          setShowErrorAlert({
            showStatus: true,
            heading: `Oh snap! You got an error!`,
            message: `There is something going wrong,please try again`,
            variant: "danger",
          });
        }
      },
      mobile,
      web_token,
      pageName
    );
  }, [mobile, web_token, pageName, column]);
  const handleErrorBox = () => {
    setShowErrorAlert(false);
  };
  return (
    <>
      <Validate />

      {loading ? <Loader /> : ""}
      <div className="bodycontainer">
        <div className="auto-space">
          <div className="row">
            <div className="col-lg-12">
              <div className="fixedAddButton">
                <Link
                  className="btn btn-success mr-1"
                  to="/order-dashboard/add-simple-strategy"
                  title="Add Strategy"
                >
                  <i className="fa fa-pencil"></i>
                </Link>

                <button onClick={refreshPage} className="btn btn-success ">
                  <i className="fa fa-refresh"></i>
                </button>
              </div>
              <Breadcrumbs page="Position Dashboard" />
            </div>
            <div className="col-lg-12">
              <OrderBreadcum pageName={pageName} />
              {isShowErrorAlert?.showStatus === true && (
                <Alert
                  showStatus={isShowErrorAlert?.showStatus}
                  heading={isShowErrorAlert?.heading}
                  message={isShowErrorAlert?.message}
                  variant={isShowErrorAlert?.variant}
                  handleClick={handleErrorBox}
                />
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              {result?.column && (
                <CustomTable
                  key={""}
                  columndata={result?.column ? result?.column : ""}
                  marketdata={result?.result ? result?.result : ""}
                  redirectlink="/order-dashboard/"
                  pageSizeNumber="10"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
