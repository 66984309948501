import React, { useEffect, useState } from "react";
import Breadcrumbs from "./../../component/breadcrumbs";
import RestService from "./../../Restservice";
import { Link } from "react-router-dom";
import MobileSubscription from "./MobileSubscription";
import Loader from "../../component/Loader";
import GlobalHelmet from "../../GlobalHelmet";
import Common from "./../../common.json";

export default function Subscription() {
  const [loading, setLoading] = useState(false);
  const [ntime, setntime] = useState(false);
  const [globaldata, setglobaldata] = useState({});

  let mobile = "";
  let web_token = "";
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  mobile = userdata.mobile ? userdata.mobile : "";
  web_token = userdata.web_token ? userdata.web_token : "";

  useEffect(() => {
    setLoading(true);
    RestService.getSubscriptionList(
      function (res) {
        setLoading(false);
        const response = JSON.parse(res);
        setglobaldata(response.data);
      },
      mobile,
      web_token
    );
    setLoading(false);
  }, [mobile, web_token, ntime]);

  const refreshPage = () => {
    var d = new Date();
    setntime(d.getTime());
  };

  return (
    <>
      {loading ? <Loader /> : ""}
      <GlobalHelmet {...Common?.subscriptionFeature} />
      <div className="bodycontainer">
        <div className="auto-space">
          <div className="row">
            <div className="col-sm-12">
              <Breadcrumbs page="Subscription" />
              <div className="fixedAddButton">
                <button
                  className="btn btn-success"
                  title="Reload Page"
                  onClick={refreshPage}
                >
                  <i className="fa fa-refresh"></i>
                </button>
                <Link
                  className="btn btn-success ml-1"
                  to={"/subscription-feature"}
                >
                  <i className="fa fa-arrow-left"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-12">
              <h1 className="highlighttext">Subscription</h1>
              <p>
                Get the right stocks at right time by robo for swing trading,
                Intraday stocks, BTST stocks, Positional trading stocks
              </p>
            </div>
            <div className="col-sm-3 col-6 mt-1 mb-2">
              <a
                href={globaldata?.socialLink?.telegram_link}
                className="btn btn-block btn-primary"
              >
                <i className="fa fa-telegram" aria-hidden="true"></i> Telegram
              </a>
            </div>
            <div className="col-sm-3 col-6  mt-1 mb-2">
              <a
                href={globaldata?.socialLink?.youtube_link}
                target="_blank"
                className="btn btn-block btn-danger"
                rel="noopener noreferrer"
              >
                <i className="fa fa-youtube-play" aria-hidden="true"></i>{" "}
                Youtube
              </a>
            </div>
            <div className="col-sm-12 pt-4">
              <MobileSubscription
                modifiedArray={globaldata.result}
                userStatus={globaldata.userStatus}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

//https://codepen.io/ankitstarski/pen/QgLXML?editors=0010
