import { React } from "react";
import "./index.css";
import "./App.css";
import "./responsive.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Router from "./router/index";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import rootReducer from './reducers';
const store = createStore(rootReducer);

function App() {
  return <Provider store={store}>
            <Router />
        </Provider>;
}

export default App;
