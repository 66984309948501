import React from "react";
import { Helmet } from "react-helmet";
import Common from "./common.json";

export default function GlobalHelmet(props) {
  props = Object.keys(props).length !== 0 ? props : Common.home;
  const location = window.location.href ?? "https://www.mntreecapital.com/";

  return (
    <Helmet>
      <title>{props[0]?.title}</title>
      <meta property="og:title" content={location}></meta>
      <meta name="description" content={props[0]?.description} />
      <meta name="keywords" content={props[0]?.keywords} />
      <meta name="author" content={props[0]?.author} />
      <link rel="canonical" href={location} />
    </Helmet>
  );
}
