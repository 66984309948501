import React from "react";
import { Redirect } from "react-router";

export default function Authvalidate() {
  let mobile = "";
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  mobile = userdata.mobile ? userdata.mobile : "";
  if (!mobile) {
    return <Redirect to={"/login"} />;
  }

  return <div></div>;
}
