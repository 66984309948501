import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import ReactHtmlParser from "react-html-parser";

export default function breadcrumbs(props) {
  const addonClas = props?.addon === "addon" ? "addonbreadcrumbsSpace" : "";
  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        className={"breadcrumbs " + addonClas}
      >
        <Link color="inherit" to="/">
          Home
        </Link>
        { props?.extraLink && props?.extraLinkText &&
          <Link color="inherit" to={props?.extraLink}>
          {props?.extraLinkText}
        </Link>

        }
        <Typography color="textPrimary">
          {ReactHtmlParser(props?.page)}
        </Typography>
      </Breadcrumbs>
    </>
  );
}
