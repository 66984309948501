import axios from "axios";

//const TokenStr = "123456789"; // process.env.tokenStr;
const Baseurl = process.env.REACT_APP_base_url;

var RestService = {
  getSubscriptionData(callback, mobile, web_token, pageName) {
    axios
      .request({
        url: `${Baseurl}Subscription/subWebFunction?mobile=${mobile}&web_token=${web_token}&pageName=${pageName}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
};

export default RestService;
