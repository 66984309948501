import { ADD_FOOTERMENU, DELETE_FOOTERMENU, ADD_FOOTERMENU_LOOP } from '../actions/types';

export default function footerMenuReducer(state = [], action) {
   switch (action.type) {
      case ADD_FOOTERMENU:
         return [...state, action.payload];
      case DELETE_FOOTERMENU:
         return state.filter(footerMenu => footerMenu.id !== action.payload.id);
      case ADD_FOOTERMENU_LOOP:
            return [...state, action.payload];
      default:
         return state;
   }
}