import React from 'react'
import GridLoader from "react-spinners/GridLoader";

import { css } from "@emotion/react";
const override = css` display: block; margin: 0 auto;  border-color: red;  position: fixed;  margin: auto;    top: 40%;    right: 40%;    z-index: 9999999;`;
 const color = '#1b95e0'; 
export default function Loader() {
    return (
          <div className="loadingplace">
                <GridLoader color={color} css={override} size={50} /> 
          </div> 
    )
}
