import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "./../../component/breadcrumbs";
import Validate from "./../../component/Authvalidate";
import CustomTable from "./CustomTable";
import Loader from "../../component/Loader";
import RestService from "./RestService";
import OrderBreadcum from "./OrderBreadcum";

export default function Dashboard() {
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  const mobile = userdata.mobile ? userdata.mobile : "";
  const web_token = userdata.web_token ? userdata.web_token : "";
  const [loading, setLoading] = useState(false);
  const [result, setresult] = useState(false);
  const [column, setColumn] = useState(false);

  function refreshPage() {
    var d = new Date();
    var n = d.getTime();
    setColumn(n);
  }

  useEffect(() => {
    setLoading(true);
    RestService.getOrderRecord(
      function (res) {
        setLoading(false);
        const response = JSON.parse(res);
        if (response.status === 200) {
          setresult(response.data);
        } else {
        }
      },
      mobile,
      web_token
    );
  }, [mobile, web_token, column]);
  return (
    <>
      <Validate />
      {loading ? <Loader /> : ""}
      <div className="bodycontainer">
        <div className="auto-space">
          <div className="row">
            <div className="col-lg-12">
              <div className="fixedAddButton">
                <Link
                  className="btn btn-success mr-1"
                  to="/order-dashboard/add-strategy"
                  title="Add Strategy"
                >
                  <i className="fa fa-pencil"></i>
                </Link>
                <Link
                  className="btn btn-success mr-1"
                  to="/order-dashboard/search"
                >
                  <i className="fa fa-search"></i>
                </Link>
                <button onClick={refreshPage} className="btn btn-success ">
                  <i className="fa fa-refresh"></i>
                </button>
              </div>
              <Breadcrumbs page="Dashboard" />
            </div>
            <div className="col-lg-12">
              <OrderBreadcum pageName="MainDasboard" />
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <CustomTable
                key={""}
                columndata={result?.column ? result?.column : ""}
                marketdata={result?.result ? result?.result : ""}
                redirectlink="/order-dashboard/"
                pageSizeNumber="10"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
