import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import SelectBox from "../../component/SelectBox";
import { Link, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RestService from "./RestService";
import GlobalHelmet from "./../../GlobalHelmet";
import Loader from "./../../component/Loader";
import Breadcrumbs from "./../../component/breadcrumbs";
import Validate from "./../../component/Authvalidate";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { createBrowserHistory } from "history";
const history = createBrowserHistory({
  forceRefresh: true,
});

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));
export default function AddStrategy() {
  let userParams = useParams();
  let order_id = userParams.order_id ? userParams.order_id : 0;

  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  const mobile = userdata.mobile ? userdata.mobile : "";
  const web_token = userdata.web_token ? userdata.web_token : "";
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [getsectorid, setsectorid] = React.useState(false);
  const [getOptionId, setOptionId] = useState(false);
  const [error, setError] = useState({});
  const [formData, setFormData] = useState(false);
  const [typeStatus, setTypeStatus] = useState(false);
  const [value, setValue] = React.useState("Buy");
  const [product, setProduct] = React.useState("Intraday");
  const [result, setresult] = useState(false);
  const [type, settype] = useState(false);
  const [submitDisabled, setsubmitDisabled] = useState(false);

  const validationSchema = Yup.object().shape({
    quantity: Yup.string()
      .required("Quantity is required")
      .matches(/^[0-9]+$/, "Quantity Must be only digits"),
    stoploss: Yup.string()
      .required("Stop Loss is required")
      .matches(/^[0-9]+$/, "Stop Loss Must be only digits"),
    noOfStock: Yup.string()
      .required("Number of Stock is required")
      .matches(/^[0-9]+$/, "Number of Stock Must be only digits"),
    profitTarget: Yup.string()
      .required("Profit Target is required")
      .matches(/^[0-9]+$/, "Profit Target Must be only digits"),
    //sectorid: Yup.string().ensure().required("Sector is required"),
  });
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });
  function onSubmit(fdata) {
    let flag = 1;
    let sectorError,
      strategyTypeError,
      strategyError = "";
    if (!getsectorid || getsectorid?.value === false) {
      sectorError = "Secotor is required";
      flag = 0;
    }
    if (!type || type?.value === false) {
      strategyTypeError = "Strategy Type is required";
      flag = 0;
    }
    if (!getOptionId || getOptionId?.value === false) {
      strategyError = "Strategy  is required";
      flag = 0;
    }

    setError({ sectorError, strategyTypeError, strategyError });
    if (flag === 1) {
      let data = new FormData();
      data.append("quantity", fdata.quantity);
      data.append("stoploss", fdata.stoploss);
      data.append("noOfStock", fdata.noOfStock);
      data.append("profitTarget", fdata.profitTarget);

      data.append("getsectorid", getsectorid.value);
      data.append("type", type.value);
      data.append("getOptionId", getOptionId?.value);
      data.append("signaltype", value);
      data.append("product", product);
      data.append("mobile", mobile);
      data.append("web_token", web_token);
      data.append("order_id", order_id);
      setsubmitDisabled(true);
      RestService.submitOrderData(
        function (res) {
          const response = JSON.parse(res);

          if (response.data.status === 1) {
            setsubmitDisabled(false);
            history.push("/order-dashboard");
          }
        },
        data,
        web_token
      );
    }

    //history.push("/otp/" + fdata.mobile);
  }

  useEffect(() => {
    setLoading(true);
    RestService.getBackdataRecord(
      function (res) {
        setLoading(false);
        const response = JSON.parse(res);
        const orderData = response.data?.orderData;
        if (response.data.result) {
          if (orderData) {
            setsectorid(orderData?.selectedSector);
            settype(orderData?.strategyType);
            setOptionId(orderData?.strategyOption);
            setValue(orderData?.signaltype);
            setProduct(orderData?.product);
            setFormData(orderData);
          }
          setresult(response.data);
        }
      },
      mobile,
      web_token,
      typeStatus,
      order_id
    );
  }, [mobile, web_token, typeStatus, order_id]);
  const changetype = (event) => {
    settype(event);
    setTypeStatus(event.value);
    setOptionId(false);
  };
  const getSectorData = (event, value) => {
    if (value) {
      setsectorid(event);
    } else {
      setsectorid(false);
    }
  };

  const getStrategyOption = (event) => {
    setOptionId(event);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };

  return (
    <>
      <Validate />
      <GlobalHelmet />
      {loading ? <Loader /> : ""}

      <div className="bodycontainer">
        <div className="auto-space">
          <div className="row">
            <div className="col-lg-12">
              <div className="fixedAddButton">
                <Link className="btn btn-success" to="/order-dashboard">
                  <i className="fa fa-arrow-left"></i>
                </Link>
              </div>
              <Breadcrumbs page="Dashboard" />

              <div className={classes.root}>
                <section className="contactCntr">
                  <form
                    className={classes.form}
                    onSubmit={handleSubmit(onSubmit)}
                    onReset={reset}
                  >
                    <div className="contactForm">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="contactLft">
                            <div className="row">
                              {result?.getSector ? (
                                <>
                                  <div className="col-sm-4">
                                    <div className="form-group">
                                      <label>Select Sector</label>

                                      <SelectBox
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                        menuPosition={"fixed"}
                                        onChange={getSectorData}
                                        options={
                                          result?.getSector
                                            ? result?.getSector
                                            : []
                                        }
                                        defaultValue={getsectorid}
                                        name="sectorid"
                                        className={
                                          classes.setSelect +
                                          " mr-2  select-option"
                                        }
                                        placeholder="Select Sector"
                                      />
                                      <span className="txtRed">
                                        {error?.sectorError}
                                      </span>
                                      <div className="invalid-feedback">
                                        {errors.sectorid?.message}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-sm-4">
                                    <div className="form-group">
                                      <label>Stratergy Type</label>
                                      <SelectBox
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                        menuPosition={"fixed"}
                                        onChange={changetype}
                                        options={strategyType}
                                        defaultValue={type}
                                        name="sectorid"
                                        className={
                                          classes.setSelect +
                                          " mr-2  select-option"
                                        }
                                        placeholder="Select Stratergy Type"
                                      />
                                      <span className="txtRed">
                                        {error?.strategyTypeError}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="col-sm-4">
                                    <div className="form-group">
                                      <label>Select Stratergy </label>
                                      <SelectBox
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                        menuPosition={"fixed"}
                                        onChange={getStrategyOption}
                                        options={
                                          result?.result ? result?.result : []
                                        }
                                        defaultValue={getOptionId}
                                        name="strategyOption"
                                        className={
                                          classes.setSelect +
                                          " mr-2  select-option"
                                        }
                                        placeholder="Select Stratergy"
                                      />
                                      <span className="txtRed">
                                        {error?.strategyError}
                                      </span>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="row">
                              <div className="col-sm-3">
                                <label>Quantity</label>
                                <input
                                  type={"text"}
                                  className={`form-control ${
                                    errors.quantity ? "is-invalid" : ""
                                  }`}
                                  placeholder="Enter Quantity"
                                  ref={register}
                                  name={"quantity"}
                                  autoComplete={"false"}
                                  defaultValue={formData?.quantity}
                                />
                                <div className="invalid-feedback">
                                  {errors.quantity?.message}
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <label>No. of Stock</label>
                                <input
                                  type={"text"}
                                  className={`form-control ${
                                    errors.noOfStock ? "is-invalid" : ""
                                  }`}
                                  placeholder="Enter Number of Stock"
                                  ref={register}
                                  name={"noOfStock"}
                                  autoComplete={"false"}
                                  defaultValue={formData?.noOfStock}
                                />
                                <div className="invalid-feedback">
                                  {errors.noOfStock?.message}
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <label>Profit Target (%)</label>
                                <input
                                  type={"text"}
                                  className={`form-control ${
                                    errors.profitTarget ? "is-invalid" : ""
                                  }`}
                                  placeholder="Enter Profit Target"
                                  ref={register}
                                  name={"profitTarget"}
                                  autoComplete={"false"}
                                  defaultValue={formData?.profitTarget}
                                />
                                <div className="invalid-feedback">
                                  {errors.profitTarget?.message}
                                </div>
                              </div>
                              <div className="col-sm-3">
                                <label>Stop Loss (%)</label>
                                <input
                                  type={"text"}
                                  className={`form-control ${
                                    errors.stoploss ? "is-invalid" : ""
                                  }`}
                                  placeholder="Enter Stop Loss"
                                  ref={register}
                                  name={"stoploss"}
                                  autoComplete={"false"}
                                  defaultValue={formData?.stoploss}
                                />
                                <div className="invalid-feedback">
                                  {errors.stoploss?.message}
                                </div>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-sm-3 ">
                                <label>Type :</label>
                                <RadioGroup
                                  aria-label="signaltype"
                                  name="signaltype"
                                  value={value}
                                  onChange={handleChange}
                                >
                                  <div className="row">
                                    <div className="col-sm-6 col-6">
                                      <FormControlLabel
                                        value="Buy"
                                        control={<Radio />}
                                        label="Buy"
                                      />
                                    </div>
                                    <div className="col-sm-6 col-6">
                                      <FormControlLabel
                                        value="Sell"
                                        control={<Radio />}
                                        label={"Sell"}
                                      />
                                    </div>
                                  </div>
                                </RadioGroup>
                              </div>
                              <div className="col-sm-3 ">
                                <RadioGroup
                                  aria-label="signaltype"
                                  name="product"
                                  value={product}
                                  onChange={handleProductChange}
                                >
                                  <label>Product :</label>
                                  <div className="row">
                                    <div className="col-sm-6 col-6">
                                      <FormControlLabel
                                        value="Intraday"
                                        control={<Radio />}
                                        label="Intraday"
                                      />
                                    </div>
                                    <div className="col-sm-6 col-6">
                                      <FormControlLabel
                                        value="Delivery"
                                        control={<Radio />}
                                        label={"Delivery"}
                                      />
                                    </div>
                                  </div>
                                </RadioGroup>
                              </div>
                              <div className="col-sm-6 mt-3 text-right">
                                <input
                                  type="submit"
                                  name="submit"
                                  disabled={submitDisabled}
                                  className="btn btn-success"
                                  defaultValue="SUBMIT &raquo;"
                                />
                                {submitDisabled ? (
                                  <CircularProgress color="secondary" />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const strategyType = [
  //{ label: "My Strategies", value: "strategies" },
  { label: "Autosignal", value: "autosignal" },
  { label: "Trading Tools", value: "tradingTool" },
];
