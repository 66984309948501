import Alert from "react-bootstrap/Alert";

export default function AlertComponent(props) {
  const show = props?.showStatus;

  return (
    <div
      className="mt-2 mb-2"
      style={{ display: show === true ? "block" : "none" }}
    >
      <Alert
        variant={props.variant ? props.variant : "success"}
        onClose={props?.handleClick}
        dismissible
      >
        <Alert.Heading>{props?.heading} !</Alert.Heading>
        <p>{props?.message}</p>
      </Alert>
    </div>
  );
}
