import React, { useState } from "react";
import MaterialTable from "material-table";
import { Link } from "react-router-dom";
import tableIcons from "./IconsTable";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OrderService from "./RestService";
import { createBrowserHistory } from "history";
import Popup from "./Popup";
const history = createBrowserHistory({
  forceRefresh: true,
});

export default function CustomTable(props) {
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  const mobile = userdata.mobile ? userdata.mobile : "";
  const web_token = userdata.web_token ? userdata.web_token : "";
  const redirectlink = props.redirectlink;
  let marketdata = props.marketdata ? props.marketdata : [];
  let pageSizeNumber = props.pageSizeNumber ? props.pageSizeNumber : 20;
  const [isOpenDelete, setOpenDelete] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [openSimpleOrder, setOpenSimpleOrder] = useState(false);
  const [details, setDetails] = useState(false);
  const [submitDisabled, setsubmitDisabled] = useState(false);
  const theme = useTheme();
  const isMob = useMediaQuery(theme.breakpoints.down("sm"));
  const customColumn = props.columndata;
  let column = [];

  const handlePortFolioClick = (orderId, signaltype) => {
    if (orderId && signaltype) {
      OrderService.updateReOrder(
        function () {
          //window.location.reload();
        },
        mobile,
        web_token,
        orderId,
        signaltype
      );
    }
  };

  if (customColumn) {
    customColumn?.forEach((item) => {
      if (item.dataField === "symbol") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => (
            <Link to={redirectlink + rowData.symbol}>{rowData.symbol} </Link>
          ),
        });
      } else if (item.dataField === "detailsSymbol") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => (
            <>
              {parseInt(rowData.processStatus) !== 1 ? (
                <Popup
                  orderId={rowData?.orderId}
                  urlLink={redirectlink + rowData.symbol}
                  symbol={rowData.symbol}
                  orderType={rowData.orderType}
                  getPageUpdatedStatus={props.getPageUpdatedStatus}
                  mobile={mobile}
                  web_token={web_token}
                />
              ) : (
                <Link to={redirectlink + rowData.symbol}>
                  {rowData.symbol}{" "}
                </Link>
              )}
            </>
          ),
        });
      } else if (item.dataField === "positionAction") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => (
            <>
              {parseInt(rowData.processStatus) !== 1 ? (
                <>
                  <Link
                    to={`/order-dashboard/add-simple-strategy?symbol=${rowData.symbol}&type=Buy`}
                    className="btn btn-success btn-sm mr-1"
                    target="_blank"
                    onClick={(e) =>
                      handlePortFolioClick(rowData.orderId, "Buy")
                    }
                  >
                    Buy
                  </Link>
                  <Link
                    to={`/order-dashboard/add-simple-strategy?symbol=${rowData.symbol}&type=Sell`}
                    className="btn btn-danger btn-sm"
                    target="_blank"
                    onClick={(e) =>
                      handlePortFolioClick(rowData.orderId, "Sell")
                    }
                  >
                    Sell
                  </Link>
                </>
              ) : (
                <>-</>
              )}
            </>
          ),
        });
      } else if (item.dataField === "info") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => (
            <i
              title="View Info"
              onClick={() => showInfo(rowData.info)}
              className="btn-sm fa fa-info btn-primary"
            ></i>
          ),
        });
      } else if (item.dataField === "orderAction") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => (
            <>
              <Link
                to={`/order-dashboard/edit-strategy/${rowData.orderAction}`}
              >
                <i className="btn-sm fa fa-edit btn-primary mr-1"></i>
              </Link>
              <i
                onClick={() => showInfo(rowData.orderAction)}
                className="btn-sm  fa fa-trash btn-danger "
              ></i>
            </>
          ),
        });
      } else if (item.dataField === "simpleOrderAction") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => (
            <>
              <Link
                to={`/order-dashboard/edit-simple-strategy/${rowData.orderId}`}
              >
                <i className="btn-sm fa fa-edit btn-primary mr-1"></i>
              </Link>
              <i
                onClick={() => showSimpleInfo(rowData.orderId, rowData.symbol)}
                className="btn-sm  fa fa-trash btn-danger "
              ></i>
            </>
          ),
        });
      } else if (item.dataField === "deleteStock") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => (
            <>
              <i
                onClick={() => deleteStock(rowData.orderId, rowData.symbol)}
                className="btn-sm  fa fa-trash btn-danger "
              ></i>
            </>
          ),
        });
      } else if (item.dataField === "orderId") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => (
            <Link to={redirectlink + rowData.orderId}>{rowData.orderId} </Link>
          ),
        });
      } else if (item.dataField === "name") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => rowData.name,
        });
      } else if (item.dataField === "cp") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.cp < 0 ? "txtRed" : "txtGreen"}>
              {rowData.cp}
            </span>
          ),
        });
      } else if (item.dataField === "prftLoss") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.prftLoss < 0 ? "txtRed" : "txtGreen"}>
              {rowData.prftLoss}
            </span>
          ),
        });
      } else if (item.dataField === "currPrice") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.currPrice < 0 ? "txtRed" : "txtGreen"}>
              {rowData.currPrice}
            </span>
          ),
        });
      } else if (item.dataField === "per") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.per < 0 ? "txtRed" : "txtGreen"}>
              {rowData.per}
            </span>
          ),
        });
      } else if (item.dataField === "today") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.today < 0 ? "txtRed" : "txtGreen"}>
              {rowData.today}
            </span>
          ),
        });
      } else if (item.dataField === "pcontracts") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.pcontracts < 0 ? "txtRed" : "txtGreen"}>
              {rowData.pcontracts}
            </span>
          ),
        });
      } else if (item.dataField === "ltp") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.ltp < 0 ? "txtRed" : "txtGreen"}>
              {rowData.ltp}
            </span>
          ),
        });
      } else if (item.dataField === "t1") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.t1 < 0 ? "txtRed" : "txtGreen"}>
              {rowData.t1}
            </span>
          ),
        });
      } else if (item.dataField === "t2") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.t2 < 0 ? "txtRed" : "txtGreen"}>
              {rowData.t2}
            </span>
          ),
        });
      } else if (item.dataField === "close") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.close < 0 ? "txtRed" : "txtGreen"}>
              {rowData.close}
            </span>
          ),
        });
      } else if (item.dataField === "chg") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.chg < 0 ? "txtRed" : "txtGreen"}>
              {rowData.chg}
            </span>
          ),
        });
      } else if (item.dataField === "t3") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.t3 < 0 ? "txtRed" : "txtGreen"}>
              {rowData.t3}
            </span>
          ),
        });
      } else if (item.dataField === "weekpers") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.weekpers < 0 ? "txtRed" : "txtGreen"}>
              {rowData.weekpers}
            </span>
          ),
        });
      } else if (item.dataField === "onemonthpers") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.onemonthpers < 0 ? "txtRed" : "txtGreen"}>
              {rowData.onemonthpers}
            </span>
          ),
        });
      } else if (item.dataField === "threemonthpers") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span
              className={rowData.threemonthpers < 0 ? "txtRed" : "txtGreen"}
            >
              {rowData.threemonthpers}
            </span>
          ),
        });
      } else if (item.dataField === "sixmonthpers") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.sixmonthpers < 0 ? "txtRed" : "txtGreen"}>
              {rowData.sixmonthpers}
            </span>
          ),
        });
      } else if (item.dataField === "avvo") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.avvo < 0 ? "txtRed" : "txtGreen"}>
              {rowData.avvo}
            </span>
          ),
        });
      } else if (item.dataField === "chg_in_ol") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.chg_in_ol < 0 ? "txtRed" : "txtGreen"}>
              {rowData.chg_in_ol}
            </span>
          ),
        });
      } else if (item.dataField === "popen_int") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={rowData.popen_int < 0 ? "txtRed" : "txtGreen"}>
              {rowData.popen_int}
            </span>
          ),
        });
      } else if (item.dataField === "btintraday") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={"txtGreen"}>{rowData.btintraday}</span>
          ),
        });
      } else if (item.dataField === "stintraday") {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
          render: (rowData) => (
            <span className={"txtRed"}>{rowData.stintraday}</span>
          ),
        });
      } else if (item.dataField === "option_type") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "expiry_date") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "update_time") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "dtime") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "date") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "dateOne") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "dateTwo") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "time_stamp") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "sectorName") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "strategyType") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "strategyName") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "product") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "signaltype") {
        column.push({ title: item.text, field: item.dataField });
      } else if (item.dataField === "signal") {
        column.push({
          title: item.text,
          field: item.dataField,
          render: (rowData) => (
            <span
              className={
                rowData.signal === "IntradaySell" ||
                rowData.signal === "JackpotSell" ||
                rowData.signal === "DnTrend" ||
                rowData.signal === "LongTSell" ||
                rowData.signal === "ShortTSell"
                  ? "txtRed"
                  : "txtGreen"
              }
            >
              {rowData.signal}
            </span>
          ),
        });
      } else {
        column.push({
          title: item.text,
          field: item.dataField,
          type: "numeric",
        });
      }
    });
  }

  const handleOrderClose = () => {
    setOpenOrder(false);
  };

  const showInfo = (item) => {
    setDetails(item);
    setOpenOrder(true);
  };

  const handleDeleteOrder = () => {
    if (details) {
      setsubmitDisabled(true);
      OrderService.deleteOrderRecord(
        function (res) {
          history.push("/order-dashboard");
        },
        mobile,
        web_token,
        details
      );
    }
  };

  const deleteStock = (orderId, stock) => {
    setDetails({ ...details, orderId: orderId, stock: stock });
    setOpenSimpleOrder(true);
  };
  const handleSimpleDeleteOrder = () => {
    if (details?.orderId && details?.stock) {
      setsubmitDisabled(true);
      OrderService.deleteOrderStock(
        function () {
          window.location.reload();
        },
        mobile,
        web_token,
        details?.orderId,
        details?.stock
      );
    }
    setOpenSimpleOrder(false);
  };
  const handleSImpleOrderClose = () => {
    setOpenSimpleOrder(false);
    setsubmitDisabled(false);
  };

  const showSimpleInfo = (orderId, symbol) => {
    setDetails({ orderId: orderId, symbol: symbol });
    setOpenDelete(true);
  };
  const handleCloseOrder = () => {
    setOpenDelete(false);
    setsubmitDisabled(false);
  };
  const deleteSimpleOrder = () => {
    if (details?.orderId && details?.symbol) {
      setsubmitDisabled(true);
      OrderService.deleteSimpleOrderStock(
        function () {
          setsubmitDisabled(false);
          window.location.reload();
        },
        mobile,
        web_token,
        details?.orderId,
        details?.symbol
      );
    }
    setOpenDelete(false);
  };
  return (
    <>
      <Dialog
        open={isOpenDelete}
        onClose={handleCloseOrder}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Remove  ${details?.symbol}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Are you sure want to delete this?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleCloseOrder} className="btn-sm btn-success">
            Close
          </button>

          {submitDisabled ? (
            <button className="btn-sm btn-danger">
              Please wait <i className="fa fa-spinner fa-spin"></i>
            </button>
          ) : (
            <button onClick={deleteSimpleOrder} className="btn-sm btn-danger">
              Delete
            </button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSimpleOrder}
        onClose={handleSImpleOrderClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Remove  ${details?.stock}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Are you sure want to delete this?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={handleSImpleOrderClose}
            className="btn-sm btn-success"
          >
            Close
          </button>

          {submitDisabled ? (
            <button className="btn-sm btn-danger">
              Please wait <i className="fa fa-spinner fa-spin"></i>
            </button>
          ) : (
            <button
              onClick={handleSimpleDeleteOrder}
              className="btn-sm btn-danger"
            >
              Delete
            </button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog
        open={openOrder}
        onClose={handleOrderClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Remove Strategy"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>Are you sure want to delete this?</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button onClick={handleOrderClose} className="btn-sm btn-success">
            Close
          </button>

          {submitDisabled ? (
            <button className="btn-sm btn-danger">
              Please wait <i className="fa fa-spinner fa-spin"></i>
            </button>
          ) : (
            <button onClick={handleDeleteOrder} className="btn-sm btn-danger">
              Delete
            </button>
          )}
        </DialogActions>
      </Dialog>

      <div className="table-responsive manage-table">
        <MaterialTable
          className="newClass"
          options={{
            pageSize: parseInt(pageSizeNumber),
            pageSizeOptions: [5, 10, 20, 30, 50, 75, 100],
            toolbar: true,
            paging: true,
            fixedColumns: isMob
              ? {}
              : {
                  left: 1,
                  right: 0,
                },
          }}
          icons={tableIcons}
          title=""
          columns={column}
          data={marketdata}
        />
      </div>
    </>
  );
}
