import axios from "axios";

const TokenStr = "123456789"; // process.env.tokenStr;
const Baseurl = process.env.REACT_APP_base_url;

var RestService = {
  getFooterListData(callback) {
    axios
      .request({
        url: `${Baseurl}globalSetting/getFooterDetails`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFundamentalScreener(callback, mobile, web_token, sector_id) {
    axios
      .request({
        url: `${Baseurl}fundamentalScreener/getFundamentalList?mobile=${mobile}&web_token=${web_token}&sector_id=${sector_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  updateStrategyData(callback, mobile, web_token, formdata, strategies_id) {
    axios
      .request({
        url: `${Baseurl}Addstrategies/addStg`,
        method: "POST",
        data: formdata,
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getgetFundamentalScreenerStock(
    callback,
    mobile,
    web_token,
    screener_id,
    sector_id
  ) {
    axios
      .request({
        url: `${Baseurl}fundamentalScreener/signalStockFunction?mobile=${mobile}&web_token=${web_token}&screener_id=${screener_id}&sector_id=${sector_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getTestData(callback) {
    axios
      .request({
        url: `${Baseurl}test/getTestData`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getCommodityData(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}Commodity/commedityData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getCommoditySignalData(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}Commodity/commeditySignal?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getCommoditySignalDetailData(
    callback,
    mobile,
    web_token,
    commoditySignal_id
  ) {
    axios
      .request({
        url: `${Baseurl}Commodity/commeditySignalDetail?mobile=${mobile}&web_token=${web_token}&commoditySignal_id=${commoditySignal_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOpeninterestData(callback, mobile, web_token, exp_date, extra_id) {
    axios
      .request({
        url: `${Baseurl}FutureStock/getfilterRecord?mobile=${mobile}&web_token=${web_token}&exp_date=${exp_date}&extra_id=${extra_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getPreMarketData(callback, mobile, web_token, sector_id, parameter) {
    axios
      .request({
        url: `${Baseurl}PreMarket/getPreMarketData?mobile=${mobile}&web_token=${web_token}&sector_id=${sector_id}&parameter=${parameter}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  marketSignalData(callback, mobile, web_token, symbol, date) {
    axios
      .request({
        url: `${Baseurl}market/marketSignal?mobile=${mobile}&web_token=${web_token}&date=${date}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getChartData(callback, mobile, web_token, ex_sector_id, stock_id) {
    axios
      .request({
        url: `${Baseurl}chartData/getChartingData?mobile=${mobile}&web_token=${web_token}&ex_sector_id=${ex_sector_id}&stock_id=${stock_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getSignalAlertData(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}SignalAlert/getAllStoreRecord?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getAddSignalAlertData(callback, mobile, web_token, type) {
    axios
      .request({
        url: `${Baseurl}SignalAlert/getStoreRecord?mobile=${mobile}&web_token=${web_token}&type=${type}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  addSignalAlertData(callback, mobile, web_token, type, signalid, symbol) {
    axios
      .request({
        url: `${Baseurl}SignalAlert/addStoreRecord?mobile=${mobile}&web_token=${web_token}&type=${type}&signalid=${signalid}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  deleteSignalAlertData(callback, mobile, web_token, signalAlert_id) {
    axios
      .request({
        url: `${Baseurl}SignalAlert/deleteSignalAlert?mobile=${mobile}&web_token=${web_token}&signalAlert_id=${signalAlert_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getWatchlistSignalData(callback, mobile, web_token, signal_id, watchlist_id) {
    axios
      .request({
        url: `${Baseurl}DashboardRecord/getWatchListSignal?mobile=${mobile}&web_token=${web_token}&signal_id=${signal_id}&watchlist_id=${watchlist_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },

  getTradingToolList(
    callback,
    mobile,
    web_token,
    ex_sector_id,
    tradingTools_id
  ) {
    axios
      .request({
        url: `${Baseurl}TradingTools/getData?mobile=${mobile}&web_token=${web_token}&ex_sector_id=${ex_sector_id}&tradingTools_id=${tradingTools_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getTradingToolsPerformance(
    callback,
    mobile,
    web_token,
    ex_sector_id,
    tradingTools_id
  ) {
    axios
      .request({
        url: `${Baseurl}TradingToolsPerformance/getData?mobile=${mobile}&web_token=${web_token}&ex_sector_id=${ex_sector_id}&tradingTools_id=${tradingTools_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getTradingToolsPerformanceData(
    callback,
    mobile,
    web_token,
    ex_sector_id,
    tradingTools_id,
    start_date,
    end_date
  ) {
    axios
      .request({
        url: `${Baseurl}TradingToolsPerformance/getPerFormanceData?mobile=${mobile}&web_token=${web_token}&ex_sector_id=${ex_sector_id}&tradingTools_id=${tradingTools_id}&start_date=${start_date}&end_date=${end_date}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getSpecialScreenerList(
    callback,
    mobile,
    web_token,
    sector_id,
    signalOption_id
  ) {
    axios
      .request({
        url: `${Baseurl}SpecialScreener/stockData?mobile=${mobile}&web_token=${web_token}&sector_id=${sector_id}&signalOption_id=${signalOption_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getAllSymbols(callback, mobile, web_token, type) {
    axios
      .request({
        url: `${Baseurl}stock/getAllStockData?mobile=${mobile}&web_token=${web_token}&type=${type}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getStockSupportData(callback, mobile, web_token, symbol) {
    axios
      .request({
        url: `${Baseurl}stock/getStockSupportData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getBackdataRecord(callback, mobile, web_token, type) {
    axios
      .request({
        url: `${Baseurl}BackTest/gettypDaata?mobile=${mobile}&web_token=${web_token}&type=${type}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getStockIdeaList(callback, mobile, web_token, type) {
    axios
      .request({
        url: `${Baseurl}StockIdea/getIdea?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getStockIdeaData(callback, mobile, web_token, idea_id) {
    axios
      .request({
        url: `${Baseurl}StockIdea/stockIdeaData?mobile=${mobile}&web_token=${web_token}&idea_id=${idea_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },

  getHowToUseData(callback) {
    axios
      .request({
        url: `${Baseurl}Tutorials/getTute`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getHowToUseDetailsData(callback, tute_id) {
    axios
      .request({
        url: `${Baseurl}Tutorials/getTuteData?tute_id=${tute_id}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionOIData(callback, mobile, web_token, symbol, dateType, expdate) {
    axios
      .request({
        url: `${Baseurl}OptionchainOI/getAnalysisData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&type=${dateType}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionTradingData(callback, mobile, web_token, symbol, expdate) {
    axios
      .request({
        url: `${Baseurl}OptionTrading/getOptionTradingData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionTradingByParamData(
    callback,
    mobile,
    web_token,
    symbol,
    expdate,
    type,
    columnkey,
    optionType
  ) {
    axios
      .request({
        url: `${Baseurl}OptionTrading/getOptionTradingByParamData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&expdate=${expdate}&type=${type}&columnkey=${columnkey}&optionType=${optionType}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionTradingDate(callback, mobile, web_token, symbol, type) {
    axios
      .request({
        url: `${Baseurl}OptionTrading/getOptionTradingDate?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&type=${type}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionAnalysisData(
    callback,
    mobile,
    web_token,
    symbol,
    dateType,
    expdate
  ) {
    axios
      .request({
        url: `${Baseurl}Optionchain/getAnalysisData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&type=${dateType}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getAnalysisStockData(callback, mobile, web_token, symbol, dateType, expdate) {
    axios
      .request({
        url: `${Baseurl}Optionchain/getAnalysisStockData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&type=${dateType}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getAnalysisExpDate(callback, mobile, web_token, symbol, dateType, expdate) {
    axios
      .request({
        url: `${Baseurl}Optionchain/getAnalysisExpDate?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&type=${dateType}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionAnalysisDData(
    callback,
    mobile,
    web_token,
    symbol,
    optionType,
    expdate
  ) {
    axios
      .request({
        url: `${Baseurl}Optionchain/getBoardDataBySymbol?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&optionType=${optionType}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFutureOption(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}OptionData/getFutureData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFutureOptionData(callback, mobile, web_token, keyname, order) {
    axios
      .request({
        url: `${Baseurl}OptionData/getFutureOptionData?mobile=${mobile}&web_token=${web_token}&keyname=${keyname}&order=${order}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionchainTipsData(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}OptionchainTips/getFilterData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionchainTipsLiveData(callback, mobile, web_token, type, date) {
    axios
      .request({
        url: `${Baseurl}OptionchainLiveTips/getFilterData?mobile=${mobile}&web_token=${web_token}&type=${type}&date=${date}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionchainTechData(
    callback,
    mobile,
    web_token,
    symbol,
    dateType,
    expdate
  ) {
    axios
      .request({
        url: `${Baseurl}stock/getFilterData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&type=${dateType}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionchainAllData(
    callback,
    mobile,
    web_token,
    symbol,
    dateType,
    expdate
  ) {
    axios
      .request({
        url: `${Baseurl}Optionchain/getFilterData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&type=${dateType}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionchainExpirData(
    callback,
    mobile,
    web_token,
    symbol,
    dateType,
    expdate
  ) {
    axios
      .request({
        url: `${Baseurl}Optionchain/getFilterExpiryData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&type=${dateType}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionchainFilterData(
    callback,
    mobile,
    web_token,
    symbol,
    dateType,
    expdate
  ) {
    axios
      .request({
        url: `${Baseurl}Optionchain/getFilterDetailsData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&type=${dateType}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionchainHistoricalData(callback, mobile, web_token, pageName) {
    axios
      .request({
        url: `${Baseurl}OptionData/getFilterData?mobile=${mobile}&web_token=${web_token}&pageName=${pageName}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionchainDataBySymbol(
    callback,
    mobile,
    web_token,
    symbol,
    optionType,
    expdate
  ) {
    axios
      .request({
        url: `${Baseurl}OptionData/getFilterDataBySymbol?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&optionType=${optionType}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionchainData(
    callback,
    mobile,
    web_token,
    symbol,
    expdate,
    strickePrice,
    optionType
  ) {
    axios
      .request({
        url: `${Baseurl}OptionData/getAllRecordsByData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&expdate=${expdate}&strickePrice=${strickePrice}&optionType=${optionType}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getSupportAndTargetData(
    callback,
    mobile,
    web_token,
    symbol,
    expdate,
    strickePrice,
    optionType
  ) {
    axios
      .request({
        url: `${Baseurl}OptionData/getSupportAndTargetData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&expdate=${expdate}&strickePrice=${strickePrice}&optionType=${optionType}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getTradingTerminal(
    callback,
    mobile,
    web_token,
    filtertype,
    watchlistid,
    portfolioid,
    sectorid,
    strategyid,
    autosignalid,
    screenerid,
    buySectorid,
    sellSectorid
  ) {
    axios
      .request({
        url: `${Baseurl}TradingTerminal/getTradingTerminal?filtertype=${filtertype}&watchlist_id=${watchlistid}&portfolio_id=${portfolioid}&buySectorid=${buySectorid}&sellSectorid=${sellSectorid}&sector_id=${sectorid}&strategyid=${strategyid}&autosignalid=${autosignalid}&screenerid=${screenerid}&mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionDashboard(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}OptionData/getBoardData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionDashboardData(callback, mobile, web_token, symbol, optionType) {
    axios
      .request({
        url: `${Baseurl}OptionData/getBoardDataBySymbol?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&optionType=${optionType}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionDashboarsdData(
    callback,
    mobile,
    web_token,
    symbol,
    optionType,
    keyname,
    order,
    expdate
  ) {
    axios
      .request({
        url: `${Baseurl}OptionData/getBoardsDataBySymbol?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&optionType=${optionType}&keyname=${keyname}&order=${order}&expdate=${expdate}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getTradingTerminalData(
    callback,
    mobile,
    web_token,
    filtertype,
    watchlistid,
    portfolioid,
    sectorid,
    strategyid,
    autosignalid,
    screenerid
  ) {
    axios
      .request({
        url: `${Baseurl}TradingTerminal/getTradingTerminalData?filtertype=${filtertype}&watchlist_id=${watchlistid}&portfolio_id=${portfolioid}&sector_id=${sectorid}&strategyid=${strategyid}&autosignalid=${autosignalid}&screenerid=${screenerid}&mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getpremiumData(callback) {
    axios
      .request({
        url: `${Baseurl}page/premiumService`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getAboutData(callback) {
    axios
      .request({
        url: `${Baseurl}page/aboutdata`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getImportantData(callback) {
    axios
      .request({
        url: `${Baseurl}link/getLink`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getSymbolListData(callback, character) {
    axios
      .request({
        url: `${Baseurl}page/getSymbolList?character=${character}`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFAQData(callback) {
    axios
      .request({
        url: `${Baseurl}page/faqData`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getWhyusData(callback) {
    axios
      .request({
        url: `${Baseurl}page/whydata`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getprivacyData(callback) {
    axios
      .request({
        url: `${Baseurl}page/privacypolicy`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  gettermandconData(callback) {
    axios
      .request({
        url: `${Baseurl}page/termandcon`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getrefundpolicyData(callback) {
    axios
      .request({
        url: `${Baseurl}page/refundpolicy`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  sendOTP(callback, mobile) {
    axios
      .request({
        url: `${Baseurl}login/sendotp?mobile=${mobile}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  validateMobile(callback, mobile) {
    axios
      .request({
        url: `${Baseurl}login/validatemobile?mobile=${mobile}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  validateEmail(callback, email) {
    axios
      .request({
        url: `${Baseurl}login/validateemail?email=${email}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  validateOTP(callback, mobile, otp) {
    axios
      .request({
        url: `${Baseurl}login/validateOTP?mobile=${mobile}&otp=${otp}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  GoogleLoginService(callback, formdata) {
    axios
      .request({
        url: `${Baseurl}login/googleLogin`,
        method: "POST",
        data: formdata,
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  registerUser(callback, userdata) {
    axios
      .request({
        url: `${Baseurl}login/registeruser?userdata=${userdata}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getworkList(callback) {
    axios
      .request({
        url: `${Baseurl}Getworklist`,
        method: "GET",
        ders: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          Authorization: `Basic ${TokenStr}`,
        },
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getServiceDetails(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}page/getServiceDetails?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  gethomeList(callback, mobile, web_token, pageName) {
    axios
      .request({
        url: `${Baseurl}globalSetting/globaldata?mobile=${mobile}&web_token=${web_token}&pageName=${pageName}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  gethomeOptionList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}globalSetting/getOptionData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMydashboard(
    callback,
    mobile,
    web_token,
    watchlistid,
    portfolioid,
    sectorid
  ) {
    axios
      .request({
        url: `${Baseurl}DashboardRecord/getDashboardRecord?watchlist_id=${watchlistid}&portfolio_id=${portfolioid}&sector_id=${sectorid}&mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMyAccount(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}DashboardRecord/getMyAccountRecord?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMyLearningList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}DashboardRecord/getMyLearningRecord?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getUpdateRating(callback, formdata) {
    axios
      .request({
        url: `${Baseurl}DashboardRecord/getUpdateRating`,
        method: "POST",
        data: formdata,
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMyRatingList(callback, mobile, web_token, course) {
    axios
      .request({
        url: `${Baseurl}DashboardRecord/getMyRatingList?mobile=${mobile}&web_token=${web_token}&courseId=${course}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMyLearningDetail(callback, mobile, web_token, course) {
    axios
      .request({
        url: `${Baseurl}DashboardRecord/getMyLearningDetail?mobile=${mobile}&web_token=${web_token}&courseId=${course}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  saveStockWatchList(callback, formdata) {
    axios
      .request({
        url: `${Baseurl}stock/saveStockWatchList`,
        method: "POST",
        data: formdata,
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  gethStockData(callback, symbol, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}stock/stockDetails?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMarketDetailData(callback, symbol, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}market/marketDetails?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  gethStockScanData(callback, symbol, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}stock/stockScanDetails?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  gethStockAnalysisData(callback, symbol, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}stock/gethStockAnalysisData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  gethFuturelysisData(callback, symbol, mobile, web_token, expiry_date) {
    axios
      .request({
        url: `${Baseurl}stock/gethFuturelysisData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&expiry_date=${expiry_date}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  gethStockScreenerData(
    callback,
    symbol,
    mobile,
    web_token,
    expiry_date,
    option
  ) {
    axios
      .request({
        url: `${Baseurl}stock/getStockData?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&expiry_date=${expiry_date}&option=${option}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getBoardsDataBySymbol(
    callback,
    symbol,
    mobile,
    web_token,
    expiry_date,
    optionType,
    order,
    keyname
  ) {
    axios
      .request({
        url: `${Baseurl}stock/getBoardsDataBySymbol?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}&expdate=${expiry_date}&order=${order}&optionType=${optionType}&keyname=${keyname}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getHeatMapData(callback, mobile, web_token, sector_id, type, filter) {
    axios
      .request({
        url: `${Baseurl}HeatMap/getlist?mobile=${mobile}&web_token=${web_token}&sector_id=${sector_id}&type=${type}&filter=${filter}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getHeatMapDataBySymbol(callback, mobile, web_token, symbol) {
    axios
      .request({
        url: `${Baseurl}HeatMap/getlistBySymbol?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionStrategiesData(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}OptionStrategies/getlist?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOptionStrategiesDetails(callback, mobile, web_token, strategyId) {
    axios
      .request({
        url: `${Baseurl}OptionStrategies/getDetails?mobile=${mobile}&web_token=${web_token}&strategyId=${strategyId}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getautosignal(callback, typevalue, signaltype) {
    axios
      .request({
        url: `${Baseurl}autoSignal/signalFunction?sector_id=${signaltype}&get_id=${typevalue}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getautosignalWinning(callback, typevalue, signaltype, signal_id) {
    axios
      .request({
        url: `${Baseurl}autoSignal/winningData?sector_id=${signaltype}&get_id=${typevalue}&signal_id=${signal_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getScreenerWinning(callback, typevalue, signaltype, signal_id) {
    axios
      .request({
        url: `${Baseurl}screener/winningData?sector_id=${signaltype}&get_id=${typevalue}&signal_id=${signal_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getautosignalCommon(callback, get_id) {
    axios
      .request({
        url: `${Baseurl}autoSignal/allCommonscan?get_id=${get_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFreeTips(callback, mobile, web_token, action) {
    axios
      .request({
        url: `${Baseurl}StockTips/freeStockTips?mobile=${mobile}&web_token=${web_token}&action=${action}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getRoboTips(callback, mobile, web_token, type, idea_type, date) {
    axios
      .request({
        url: `${Baseurl}StockIdea/freeStockIdea?mobile=${mobile}&web_token=${web_token}&type=${type}&idea_type=${idea_type}&date=${date}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getRoboTipsFav(callback, mobile, web_token, type, idea_type, symbol) {
    axios
      .request({
        url: `${Baseurl}StockIdea/markedFav?mobile=${mobile}&web_token=${web_token}&type=${type}&idea_type=${idea_type}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFreeTipsFav(callback, mobile, web_token, action, symbol) {
    axios
      .request({
        url: `${Baseurl}StockTips/getSymbolFav?mobile=${mobile}&web_token=${web_token}&action=${action}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getPortfolioSymbolList(callback, mobile, web_token, portfolio_id, type) {
    axios
      .request({
        url: `${Baseurl}portfolio/getsymbollist?mobile=${mobile}&web_token=${web_token}&portfolio_id=${portfolio_id}&type=${type}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getSubscriptionList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}subscription/packageDetails?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getPortfolioList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}portfolio/getlist?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getPortfolioAnalysisList(callback, mobile, web_token, portfolioid) {
    axios
      .request({
        url: `${Baseurl}PortfolioAnalysis/getlist?mobile=${mobile}&web_token=${web_token}&portfolioid=${portfolioid}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getPortfolioExitList(callback, mobile, web_token, type) {
    axios
      .request({
        url: `${Baseurl}PortfolioAnalysis/getExitStock?mobile=${mobile}&web_token=${web_token}&type=${type}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  deletePortfolioStock(callback, mobile, web_token, symbol) {
    axios
      .request({
        url: `${Baseurl}PortfolioAnalysis/deletePortfolioStock?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  deletePortfolio(callback, mobile, web_token, portfolio_id) {
    axios
      .request({
        url: `${Baseurl}portfolio/deleteportfolio?mobile=${mobile}&web_token=${web_token}&portfolio_id=${portfolio_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  updatePortfolioData(callback, mobile, web_token, data) {
    axios
      .request({
        url: `${Baseurl}portfolio/updateportfolioData?mobile=${mobile}&web_token=${web_token}&data=${data}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  updatePortfolioStockData(
    callback,
    mobile,
    web_token,
    portfolio_id,
    data,
    stock_id
  ) {
    axios
      .request({
        url: `${Baseurl}portfolio/updateportfolioStocckData?mobile=${mobile}&web_token=${web_token}&portfolio_id=${portfolio_id}&data=${data}&stock_id=${stock_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getPortfolioStock(callback, mobile, web_token, portfolio_id, type) {
    axios
      .request({
        url: `${Baseurl}portfolio/getStock?mobile=${mobile}&web_token=${web_token}&portfolio_id=${portfolio_id}&type=${type}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getCashPriceList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}PricePattern/getSymbol?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getCashPriceSymbolData(callback, mobile, web_token, symbol) {
    axios
      .request({
        url: `${Baseurl}PricePattern/getPricePattern?mobile=${mobile}&web_token=${web_token}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFuturePriceList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}FutureStock/getFutureStock?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFutureStockList(callback, mobile, web_token, future, exp_date, symbol) {
    axios
      .request({
        url: `${Baseurl}FutureStock/getFutureData?mobile=${mobile}&web_token=${web_token}&future=${future}&exp_date=${exp_date}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getCommonDataList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/combineMarketData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getTodayActivityList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getRec?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFIIDIIIList(callback, mobile, web_token, paramone, paramtwo) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getFidiData?mobile=${mobile}&web_token=${web_token}&param_one=${paramone}&param_two=${paramtwo}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getEventList(callback, mobile, web_token, date) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getEventData?mobile=${mobile}&web_token=${web_token}&date=${date}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getEventPerformanceList(callback, mobile, web_token, date) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getEventPerFormanceData?mobile=${mobile}&web_token=${web_token}&date=${date}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getActionList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getActionData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFiiDataList(callback, mobile, web_token, paramone, paramtwo) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getFiiData?mobile=${mobile}&web_token=${web_token}&param_one=${paramone}&param_two=${paramtwo}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getadvList(callback, mobile, web_token, paramone, paramtwo) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getAdvDecdData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getIndiaVixRatioData(callback, mobile, web_token, paramone, paramtwo) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getIndiaVixRatioData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMWequitiesData(callback, mobile, web_token, paramone, paramtwo) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getMWequitiesData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMWequitiesDetails(callback, mobile, web_token, symbol) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getMWequitiesDetails?mobile=${mobile}&web_token=${web_token}}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMarketBreadthData(callback, mobile, web_token, paramone, paramtwo) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getTodayMarketData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getMarketBreadthStockData(callback, mobile, web_token, ex_sector_id) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getMarketbbreadthData?ex_sector_id=${ex_sector_id}&mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getDerivativeData(callback, mobile, web_token, paramone, paramtwo) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getDerivativeData?mobile=${mobile}&web_token=${web_token}&paramone=${paramone}&paramtwo=${paramtwo}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getPullCallRatioData(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getPullCallRatioData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getFiftyTwoData(callback, mobile, web_token, paramone, paramtwo) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getFiftyTwoData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getblockDealsData(callback, mobile, web_token, date, page) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getblockDealsData?mobile=${mobile}&web_token=${web_token}&date=${date}&page=${page}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },

  getbulkDealsData(callback, mobile, web_token, date, page) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getbulkDealsData?mobile=${mobile}&web_token=${web_token}&date=${date}&page=${page}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getInsiderTrading(callback, mobile, web_token, date) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/getInsiderTrading?mobile=${mobile}&web_token=${web_token}&date=${date}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getwatchList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}watchlist/getlist?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getsectorAnalysisData(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/sectorAnalysisData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getsectorAnalysisAllData(callback, mobile, web_token, ex_sector_id) {
    axios
      .request({
        url: `${Baseurl}TodayActivity/sectorAnalysisAllData?mobile=${mobile}&web_token=${web_token}&ex_sector_id=${ex_sector_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },

  getRoboProData(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}RoboPort/roboPortData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  deleteRoboData(callback, mobile, web_token, profileID) {
    axios
      .request({
        url: `${Baseurl}RoboPort/deleteRoboPort?mobile=${mobile}&web_token=${web_token}&profileID=${profileID}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getRoboProStock(callback, mobile, web_token, profileID) {
    axios
      .request({
        url: `${Baseurl}RoboPort/getRoboProStock?mobile=${mobile}&web_token=${web_token}&profileID=${profileID}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getRoboProByIdData(callback, mobile, web_token, profileID) {
    axios
      .request({
        url: `${Baseurl}RoboPort/roboPortByIdData?mobile=${mobile}&web_token=${web_token}&profileID=${profileID}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  addUpdateRoboProData(callback, formdata) {
    axios
      .request({
        url: `${Baseurl}RoboPort/addRoboPortData`,
        method: "POST",
        data: formdata,
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  updateOrderDetail(callback, formdata) {
    axios
      .request({
        url: `${Baseurl}Payment/orderDetails`,
        method: "POST",
        data: formdata,
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  updatePaymentDetail(callback, formdata) {
    axios
      .request({
        url: `${Baseurl}Payment/paymentDetails`,
        method: "POST",
        data: formdata,
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getStrategyStock(callback, mobile, web_token, strategies_id, backdate) {
    axios
      .request({
        url: `${Baseurl}Addstrategies/getStock?mobile=${mobile}&web_token=${web_token}&addstrategies_id=${strategies_id}&backdate=${backdate}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  deletestratergy(callback, mobile, web_token, strategies_id) {
    axios
      .request({
        url: `${Baseurl}Addstrategies/delstg?mobile=${mobile}&web_token=${web_token}&strategies_id=${strategies_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getwatchListStock(callback, mobile, web_token, watchlist_id) {
    axios
      .request({
        url: `${Baseurl}watchlist/watchtliststock?mobile=${mobile}&web_token=${web_token}&watchlist_id=${watchlist_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  deletewatchList(callback, mobile, web_token, watchlist_id) {
    axios
      .request({
        url: `${Baseurl}watchlist/deletewatchlist?mobile=${mobile}&web_token=${web_token}&watchlist_id=${watchlist_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getWatchlistData(callback, mobile, web_token, watchlist_id) {
    axios
      .request({
        url: `${Baseurl}watchlist/getwatchlistData?mobile=${mobile}&web_token=${web_token}&watchlist_id=${watchlist_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  updateWatchlistData(
    callback,
    mobile,
    web_token,
    watchlist_id,
    name,
    allsymbols
  ) {
    axios
      .request({
        url: `${Baseurl}watchlist/updatewatchlistData?mobile=${mobile}&web_token=${web_token}&watchlist_id=${watchlist_id}&name=${name}&allsymbols=${allsymbols}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getAutosignalStock(callback, mobile, web_token, signal_id, sector_id, date) {
    axios
      .request({
        url: `${Baseurl}autoSignal/signalStockFunction?mobile=${mobile}&web_token=${web_token}&signal_id=${signal_id}&sector_id=${sector_id}&date=${date}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getPiotroskiData(callback, mobile, web_token, sector_id) {
    axios
      .request({
        url: `${Baseurl}piotroski/piotroskiScan?mobile=${mobile}&web_token=${web_token}&sector_id=${sector_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getHourlyGainerLooser(
    callback,
    mobile,
    web_token,
    sector_id,
    interval,
    timefromTo,
    type,
    symbol
  ) {
    axios
      .request({
        url: `${Baseurl}hourlyGainerLooser/getHourlygainerlooser?mobile=${mobile}&web_token=${web_token}&sector_id=${sector_id}&interval=${interval}&timefromTo=${timefromTo}&type=${type}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getHourlyGainerLooserCombine(
    callback,
    mobile,
    web_token,
    sector_id,
    interval,
    timefromTo,
    type,
    symbol
  ) {
    axios
      .request({
        url: `${Baseurl}hourlyGainerLooser/getHourlygainerloosercombine?mobile=${mobile}&web_token=${web_token}&sector_id=${sector_id}&interval=${interval}&timefromTo=${timefromTo}&type=${type}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getGlobalMarketData(callback, typevalue, signaltype) {
    axios
      .request({
        url: `${Baseurl}global_market/globalmarketData`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getSector(callback, typevalue, signaltype) {
    axios
      .request({
        url: `${Baseurl}sector/sectorData`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getSectorStock(callback, mobile, web_token, sector_id) {
    axios
      .request({
        url: `${Baseurl}sector/sectorstock?mobile=${mobile}&web_token=${web_token}&sector_id=${sector_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getIndustry(callback, typevalue, signaltype) {
    axios
      .request({
        url: `${Baseurl}exSector/exsectorData`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getIndustryStock(callback, mobile, web_token, sector_id) {
    axios
      .request({
        url: `${Baseurl}exSector/exsectorstock?mobile=${mobile}&web_token=${web_token}&sector_id=${sector_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getScreener(callback, typevalue, signaltype) {
    axios
      .request({
        url: `${Baseurl}screener/screenerFunction?sector_id=${signaltype}&get_id=${typevalue}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getScreenerStock(callback, mobile, web_token, signal_id, sector_id, date) {
    axios
      .request({
        url: `${Baseurl}screener/scrStockFunction?mobile=${mobile}&web_token=${web_token}&signal_id=${signal_id}&sector_id=${sector_id}&date=${date}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getScreenerCommon(callback, get_id) {
    axios
      .request({
        url: `${Baseurl}screener/allCommonscan?get_id=${get_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getcolumnList(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}stock/getcolumnlist?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },

  updatecolumn(callback, mobile, token, columntype, columndata) {
    axios
      .request({
        url: `${Baseurl}stock/updatecolumn?token=${token}&mobile=${mobile}&columntype=${columntype}&columndata=${columndata}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
};

export default RestService;
