import React from "react";
import { Link } from "react-router-dom";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <div className="bodycontainer">
      <div className="auto-space">
        <section className="page_404">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 ">
                <div className="contant_box_404">
                  <img
                    src={require("./../../images/404.jpg").default}
                    alt=""
                    prop=""
                  />
                  <h3 className="h2">Look like you're lost</h3>

                  <p>the page you are looking for not avaible!</p>

                  <Link to="/" className="btn btn-primary">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PageNotFound;
