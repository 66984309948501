import axios from "axios";

const Baseurl = process.env.REACT_APP_base_url;

var RestService = {
  getStockList(callback, mobile, web_token, order_id) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/getStockList?mobile=${mobile}&web_token=${web_token}&order_id=${order_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
        //return Promise.reject(error);
      });
  },
  deleteOrderRecord(callback, mobile, web_token, order_id) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/deleteOrderRecord?mobile=${mobile}&web_token=${web_token}&order_id=${order_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  deleteOrderStock(callback, mobile, web_token, order_id, stock) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/deleteOrderStock?mobile=${mobile}&web_token=${web_token}&order_id=${order_id}&stock=${stock}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  deleteSimpleOrderStock(callback, mobile, web_token, order_id, stock) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/deleteSimpleOrderStock?mobile=${mobile}&web_token=${web_token}&order_id=${order_id}&stock=${stock}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  updateSymbolRecord(callback, mobile, web_token, order_id, symbol) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/updateOrderSymbolRecord?mobile=${mobile}&web_token=${web_token}&order_id=${order_id}&symbol=${symbol}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOrderRecord(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/getOrderData?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getSimpleOrderRecord(callback, mobile, web_token, pageName) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/getSimpleOrderData?mobile=${mobile}&web_token=${web_token}&pageName=${pageName}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
        //return Promise.reject(error);
      });
  },
  simpleOrderHistory(callback, mobile, web_token, pageName) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/simpleOrderHistory?mobile=${mobile}&web_token=${web_token}&pageName=${pageName}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
        //return Promise.reject(error);
      });
  },
  tradeOrderHistory(callback, mobile, web_token, pageName) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/tradeOrderHistory?mobile=${mobile}&web_token=${web_token}&pageName=${pageName}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
        //return Promise.reject(error);
      });
  },
  getPortFolioOrderRecord(callback, mobile, web_token) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/getPortFolioOrderRecord?mobile=${mobile}&web_token=${web_token}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getBackdataRecord(callback, mobile, web_token, type, order_id) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/gettypDaata?mobile=${mobile}&web_token=${web_token}&type=${type}&order_id=${order_id}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  getOrderdetails(callback, mobile, web_token, orderId) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/getOrderDetails?mobile=${mobile}&web_token=${web_token}&orderId=${orderId}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  updateReOrder(callback, mobile, web_token, orderId, signalType) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/updateReOrder?mobile=${mobile}&web_token=${web_token}&orderId=${orderId}&signalType=${signalType}`,
        method: "GET",
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  submitOrderData(callback, formdata, token) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/submitOrderData`,
        method: "POST",
        headers: {
          // Authorization: `Bearer ${token}`,
        },
        data: formdata,
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
  submitSimpleOrderData(callback, formdata) {
    axios
      .request({
        url: `${Baseurl}OrderDashboard/submitSimpleOrderData`,
        method: "POST",
        headers: {
          // Authorization: `Bearer ${token}`,
        },
        data: formdata,
      })
      .then(function (response) {
        callback(JSON.stringify(response));
      })
      .catch(function (error) {
        callback(JSON.stringify(error?.response));
      });
  },
};

export default RestService;
