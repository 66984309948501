import React, { useState, useEffect } from "react";
import RestService from "./Restservice";
import Breadcrumbs from "./../../component/breadcrumbs";
import Loader from "./../../component/Loader";
import { Link } from "react-router-dom";
import Common from "./../../common.json";
import GlobalHelmet from "../../GlobalHelmet";

export default function Feature() {
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  const mobile = userdata.mobile ? userdata.mobile : "";
  const web_token = userdata.web_token ? userdata.web_token : "";
  const [globaldata, setglobaldata] = useState({});
  const [loading, setLoading] = useState(false);
  const [ntime, setntime] = useState(false);

  useEffect(() => {
    setLoading(true);
    RestService.getSubscriptionData(
      function (res) {
        setLoading(false);
        const response = JSON.parse(res);
        setglobaldata(response.data);
      },
      mobile,
      web_token
    );
  }, [mobile, web_token, ntime]);

  const refreshPage = () => {
    var d = new Date();
    setntime(d.getTime());
  };
  return (
    <>
      <GlobalHelmet {...Common?.subscriptionFeature} />
      <div className="bodycontainer">
        <div className="auto-space">
          <div className="row">
            <div className="col-sm-12">
              {loading ? <Loader /> : ""}
              <div className="fixedAddButton">
                <button
                  className="btn btn-success"
                  title="Reload Page"
                  onClick={refreshPage}
                >
                  <i className="fa fa-refresh"></i>
                </button>
              </div>

              <Breadcrumbs page="Feature Comparision" />
            </div>
            <div className="col-md-12">
              <h1 className="highlighttext">Feature Comparision</h1>
              <p>
                Get the right stocks at right time by robo for swing trading,
                Intraday stocks, BTST stocks, Positional trading stocks
              </p>
            </div>
            <div className="  col-sm-3 col-6 mt-1 mb-2">
              <a
                href={globaldata?.socialLink?.telegram_link}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-block btn-primary"
              >
                <i className="fa fa-telegram" aria-hidden="true"></i> Telegram
              </a>
            </div>
            <div className="  col-sm-3 col-6  mt-1 mb-2">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={globaldata?.socialLink?.youtube_link}
                className="btn btn-block btn-danger"
              >
                <i className="fa fa-youtube-play" aria-hidden="true"></i>{" "}
                Youtube
              </a>
            </div>
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-bordered subscptionTable">
                  <thead>
                    <tr>
                    <th>Feature</th>
                    <th>Free</th>
                    <th>Pro</th>
                    <th>Pro+</th>
                    <th>F&O</th>
                    </tr>
                  </thead>
                  <tbody>
                    {globaldata &&
                      globaldata?.featureOption?.map((items, key) => (
                        <tr key={key}>
                          <td>
                            <p className="feature-content-size">{items.name}</p>
                          </td>
                          <td>
                            {parseInt(items.free) === 0 ? (
                              <i className="fa fa-times redTxt"></i>
                            ) : (
                              <i className="fa fa-check greenTxt"></i>
                            )}
                          </td>
                          <td>
                            {parseInt(items.pro) === 0 ? (
                              <i className="fa fa-times redTxt"></i>
                            ) : (
                              <i className="fa fa-check greenTxt"></i>
                            )}
                          </td>
                          <td>
                            {parseInt(items.proplus) === 0 ? (
                              <i className="fa fa-times redTxt"></i>
                            ) : (
                              <i className="fa fa-check greenTxt"></i>
                            )}
                          </td>
                          <td>
                            {parseInt(items.FAndO) === 0 ? (
                              <i className="fa fa-times redTxt"></i>
                            ) : (
                              <i className="fa fa-check greenTxt"></i>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-sm-12 mt-4 text-center">
              <Link to="/subscription" className="btn btn-success">
                View Plan
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
