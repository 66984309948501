import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "../../component/breadcrumbs";
import { yupResolver } from "@hookform/resolvers/yup";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Container from "@material-ui/core/Container";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import Validate from "./../../component/Authvalidate";
import RestService from "./RestService";
import Loader from "../../component/Loader";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createBrowserHistory } from "history";
const history = createBrowserHistory({
  forceRefresh: true,
});

const useStyles = makeStyles((theme) => ({
  setSelect: {
    width: "100%",
    display: "inline-block",
    marginRight: "10px",
  },
}));

export default function AddSimpleOrderStrategy() {
  let userParams = useParams();
  let order_id = userParams.order_id ? userParams.order_id : 0;
  const userdata = localStorage.getItem("userdata")
    ? JSON.parse(localStorage.getItem("userdata"))
    : "";
  const mobile = userdata.mobile ? userdata.mobile : "";
  const web_token = userdata.web_token ? userdata.web_token : "";
  const classes = useStyles();
  const [globalData, setGlobalData] = useState(false);
  const [stockPrice, setStockPrice] = useState("");
  const [triggerPrice, setTriggerPrice] = useState("");
  const [isDisableTriggerPrice, setDisableTriggerPrice] = useState(true);
  const [getStockData, setStockData] = useState(false);
  const [formData, setFormData] = useState(false);
  const [product, setProduct] = React.useState("Intraday");
  const [type, setType] = React.useState("Market");
  const [isDisablePrice, setDisablePrice] = useState(true);
  const [loading, setLoading] = useState(false);
  const [formFlag, setFormFlag] = useState(1);
  const [customError, setcustomError] = useState([]);
  const [result, setResult] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("Buy");
  const [isDisabled, setDisabled] = useState(false);

  const validationSchema = Yup.object().shape({
    quantity: Yup.string()
      .required("Quantity is required")
      .matches(/^[0-9]+$/, "Quantity Must be only digits"),
    price: Yup.number("Price must be a `number`"),
    triggerPrice: Yup.number("Trigger Price must be a `number`"),

    //sectorid: Yup.string().ensure().required("Sector is required"),
  });
  const { register, handleSubmit, reset, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleChange = (event) => {
    if (type === "Market") {
      setStockPrice(event?.ltp);
    }
    setStockData(event);
    setcustomError({ stockMessage: "" });
  };
  const handleProductChange = (event) => {
    setProduct(event.target.value);
  };
  const handlePrice = (event) => {
    setStockPrice(event.target.value);
  };
  const handleTriggerPrice = (event) => {
    setTriggerPrice(event.target.value);
  };

  const handleCustomCondition = () => {
    let triggerError = "";
    let triggerPriceError = "";
    let stockMessage = "";
    const stockLTP = getStockData?.ltp;
    if (type === "SL") {
      if (stockPrice <= stockLTP) {
        triggerError = `Price should be greater than ${stockLTP}`;
        setFormFlag(0);
      }
      if (!(triggerPrice > stockLTP && triggerPrice < stockPrice)) {
        triggerPriceError = `Trigger Price should be greater than ${stockPrice} and less than ${stockLTP}  `;
        setFormFlag(0);
      }
    }
    if (getStockData === false) {
      stockMessage = "Stock is required";
      setFormFlag(0);
    }
    if (
      triggerError === "" &&
      triggerPriceError === "" &&
      stockMessage === ""
    ) {
      setFormFlag(1);
    }
    setcustomError({
      ...customError,
      triggerError: triggerError,
      triggerPriceError: triggerPriceError,
      stockMessage: stockMessage,
    });
  };
  const handleTypeChange = (event) => {
    if (event.target.value === "Market") {
      const filteredResult = globalData?.result.find(
        (e) => e.value === getStockData?.value
      );
      setDisablePrice(true);
      setStockPrice(filteredResult?.ltp);
      setDisableTriggerPrice(true);
    } else if (event.target.value === "SL") {
      setDisablePrice(false);
      setDisableTriggerPrice(false);
      handleCustomCondition();
    } else {
      setDisablePrice(false);
      setDisableTriggerPrice(true);
    }
    setType(event.target.value);
  };
  const confirmOrder = () => {
    let data = new FormData();

    data.append("quantity", result.quantity);
    data.append("price", result.price);
    data.append("symbol", result.stock);
    data.append("triggerPrice", result.triggerPrice ? result.triggerPrice : 0);
    data.append("type", result.type);
    data.append("signalType", result.signaltype);
    data.append("product", result.product);
    data.append("mobile", mobile);
    data.append("web_token", web_token);
    data.append("order_id", order_id);
    setLoading(true);

    RestService.submitSimpleOrderData(function (res) {
      const response = JSON.parse(res);
      if (response.data.status === 1) {
        setLoading(false);
        history.push("/simple-order-dashboard");
      }
    }, data);
  };
  const onSubmit = (fdata) => {
    handleCustomCondition();
    if (formFlag >= 1) {
      fdata.price = stockPrice;
      fdata.stock = getStockData?.value;
      fdata.type = type;
      fdata.product = product;
      fdata.mobile = mobile;
      fdata.web_token = web_token;
      fdata.signaltype = value;
      setResult(fdata);
      setOpen(true);
    }
  };

  const handleClose = (res) => {
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    const queryParams = new URLSearchParams(window.location.search);
    let getSymbol = queryParams.get("symbol");
    let getType = queryParams.get("type");
    RestService.getStockList(
      function (res) {
        setLoading(false);
        const response = JSON.parse(res);
        const orderData = response.data?.orderData;
        if (response.status === 200) {
          setGlobalData(response.data);
          if (getSymbol) {
            let symbolsData = response.data?.result.find(
              (e) => e.value === getSymbol
            );
            setStockPrice(symbolsData?.ltp);
            setStockData(symbolsData);
          }
          if (getType) {
            setValue(getType);
          }
          if (orderData) {
            setDisabled(true);
            setFormData(orderData);
            setValue(orderData?.signaltype);
            setProduct(orderData?.product);
            setType(orderData?.type);
            setStockData(orderData.selectedStock);
            setStockPrice(orderData?.price);
            setTriggerPrice(
              orderData?.triggerPrice > 0 ? orderData?.triggerPrice : ""
            );
            if (orderData?.type === "Market") {
              setDisablePrice(true);
            } else if (orderData?.type === "SL") {
              setDisablePrice(false);
              setDisableTriggerPrice(false);
            } else {
              setDisablePrice(false);
            }
          }
        } else {
        }
      },
      mobile,
      web_token,
      order_id
    );
  }, [mobile, web_token, order_id]);

  const handleSignalType = (event) => {
    setValue(event.target.value);
  };

  const breadcumTxt =
    order_id !== 0 ? "Edit Simple Strategy" : "Add Simple Strategy";
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Order Confirmation "}
        </DialogTitle>
        <DialogContent>
          <table className="table">
            <tr>
              <td>
                <label className="font-bold">Symbol:</label>
              </td>
              <td>{result?.stock} </td>
            </tr>
            <tr>
              <td>
                <label className="font-bold">Price:</label>
              </td>
              <td>{result?.price} </td>
            </tr>
            <tr>
              <td>
                <label className="font-bold">Quantity:</label>
              </td>
              <td>{result?.quantity} </td>
            </tr>
            <tr>
              <td>
                <label className="font-bold">Type:</label>
              </td>
              <td>{result?.type} </td>
            </tr>
            <tr>
              <td>
                <label className="font-bold">Product:</label>
              </td>
              <td>{result?.product} </td>
            </tr>
            <tr>
              <td>
                <label className="font-bold">Trigger Price:</label>
              </td>
              <td>{result?.triggerPrice ? result?.triggerPrice : "-"} </td>
            </tr>
          </table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel Order
          </Button>
          <Button onClick={confirmOrder} color="success" autoFocus>
            Confirm Order
          </Button>
        </DialogActions>
      </Dialog>
      <div className="bodycontainer">
        <div className="auto-space">
          <div className="row">
            <div className="col-md-12">
              <div className="fixedAddButton">
                <Link className="btn btn-success" to="/simple-order-dashboard">
                  <i className="fa fa-arrow-left"></i>
                </Link>
              </div>
              <Breadcrumbs page={`${breadcumTxt}`} />
              <Validate />
              {loading ? <Loader /> : ""}
              <form
                className={classes.form}
                onSubmit={handleSubmit(onSubmit)}
                onReset={reset}
              >
                <Container component="main" maxWidth="sm">
                  <div className="strategy-order-form">
                    <div className="row">
                      <div className="col-sm-6 ">
                        <label className="font-bold">Select Stock</label>
                        <Select
                          styles={{
                            menuPortal: (base) => ({
                              ...base,
                              zIndex: 9999,
                            }),
                          }}
                          onChange={handleChange}
                          menuPosition={"fixed"}
                          options={globalData?.result ? globalData?.result : []}
                          name="expiry_date"
                          className={classes.setSelect + " mr-2  select-option"}
                          placeholder="Select Sector"
                          value={getStockData}
                          isDisabled={isDisabled}
                        />
                        <div
                          className="invalid-feedback"
                          style={{
                            display: customError?.stockMessage
                              ? "block"
                              : "none",
                          }}
                        >
                          {customError?.stockMessage}
                        </div>
                      </div>
                      <div className="col-sm-6 ">
                        <label>Type :</label>
                        <RadioGroup
                          aria-label="signaltype"
                          name="signaltype"
                          value={value}
                          onChange={handleSignalType}
                        >
                          <div className="row">
                            <div className="col-sm-6 col-6">
                              <FormControlLabel
                                value="Buy"
                                control={<Radio />}
                                label="Buy"
                              />
                            </div>
                            <div className="col-sm-6 col-6">
                              <FormControlLabel
                                value="Sell"
                                control={<Radio />}
                                label={"Sell"}
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="col-sm-6">
                        <label className="font-bold mt-1">Quantity</label>
                        <input
                          type={"text"}
                          className={`form-control ${
                            errors.quantity ? "is-invalid" : ""
                          }`}
                          placeholder="Enter Quantity"
                          ref={register}
                          name={"quantity"}
                          autoComplete={"false"}
                          defaultValue={formData?.quantity}
                        />
                        <div className="invalid-feedback">
                          {errors.quantity?.message}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <label className="font-bold mt-1">Price</label>
                        <input
                          type={"text"}
                          className={`form-control ${
                            errors.price ? "is-invalid" : ""
                          }`}
                          onChange={handlePrice}
                          onKeyUp={handleCustomCondition}
                          value={stockPrice}
                          placeholder="Enter Price"
                          ref={register}
                          name={"price"}
                          autoComplete={"false"}
                          disabled={isDisablePrice}
                        />
                        <div className="invalid-feedback">
                          {errors.price?.message}
                        </div>
                        <div
                          className="invalid-feedback"
                          style={{
                            display: customError?.triggerError
                              ? "block"
                              : "none",
                          }}
                        >
                          {customError?.triggerError}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <RadioGroup
                          aria-label="signaltype"
                          name="signaltype"
                          value={product}
                          onChange={handleProductChange}
                        >
                          <label className="font-bold mt-2">Product :</label>
                          <div className="row">
                            <div className="col-sm-4 col-6">
                              <FormControlLabel
                                value="Intraday"
                                control={<Radio />}
                                label="Intraday"
                              />
                            </div>
                            <div className="col-sm-6 col-6">
                              <FormControlLabel
                                value="Delivery"
                                control={<Radio />}
                                label={"Delivery"}
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="col-sm-6">
                        <label className="font-bold">Trigger</label>
                        <input
                          type={"text"}
                          className={`form-control ${
                            errors.triggerPrice ? "is-invalid" : ""
                          }`}
                          onChange={handleTriggerPrice}
                          onKeyUp={handleCustomCondition}
                          value={
                            type === "SL" && triggerPrice ? triggerPrice : ""
                          }
                          placeholder="Enter Trigger Price"
                          ref={register}
                          name={"triggerPrice"}
                          autoComplete={"false"}
                          disabled={isDisableTriggerPrice}
                        />
                        <div className="invalid-feedback">
                          {errors.triggerPrice?.message}
                        </div>
                        <div
                          className="invalid-feedback"
                          style={{
                            display: customError?.triggerPriceError
                              ? "block"
                              : "none",
                          }}
                        >
                          {customError?.triggerPriceError}
                        </div>
                      </div>

                      <div className="col-sm-8">
                        <RadioGroup
                          aria-label="signaltype"
                          name="signaltype"
                          value={type}
                          onChange={handleTypeChange}
                        >
                          <label className="font-bold">Type :</label>
                          <div className="row">
                            <div className="col-sm-4 col-4">
                              <FormControlLabel
                                value="Market"
                                control={<Radio />}
                                label="Market"
                              />
                            </div>
                            <div className="col-sm-4 col-4">
                              <FormControlLabel
                                value="Limit"
                                control={<Radio />}
                                label={"Limit"}
                              />
                            </div>
                            <div className="col-sm-4 col-4">
                              <FormControlLabel
                                value="SL"
                                control={<Radio />}
                                label={"SL"}
                              />
                            </div>
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="col-sm-4 mt-2">
                        <label className="font-bold mt-4">Validity :</label>
                        <button className=" ml-2 btn btn-default">Day</button>
                      </div>

                      <div className="col-sm-12 mt-3">
                        <input
                          type="submit"
                          name="submit"
                          className="btn btn-success"
                          defaultValue="SUBMIT &raquo;"
                        />
                      </div>
                    </div>
                  </div>
                </Container>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
