import React from "react";
import { Link } from "react-router-dom";

export default function OrderBreadcum({ pageName }) {
  return (
    <div className="row ">
      <div className="col-lg-12">
        {btnArray.map((item) => (
          <Link
            to={item.link}
            className={
              pageName === item.id
                ? "btn btn-success mr-1 mt-1"
                : "btn btn-default mr-1 mt-1"
            }
          >
            {item.name}
          </Link>
        ))}
      </div>
    </div>
  );
}

const btnArray = [
  {
    id: "MainDasboard",
    link: "/order-dashboard",
    name: "Strategy/Autosignal Order",
  },
  {
    id: "SimpleDashboard",
    link: "/simple-order-dashboard",
    name: "Simple Order",
  },
  {
    id: "TradeDasboard",
    link: "/trade-order-dashboard",
    name: "Traded Order",
  },
  {
    id: "PositionDashboard",
    link: "/position-order-dashboard",
    name: "Position",
  },
  {
    id: "PortfolioDashboard",
    link: "/portfolio-order-dashboard",
    name: "Portfolio ",
  },
  {
    id: "SimpleOrderHistory",
    link: "/simple-order-history",
    name: "Simple History ",
  },
  {
    id: "TradeOrderHistory",
    link: "/trade-order-history",
    name: "Trade History ",
  },
];
