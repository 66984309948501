import React, { useState } from "react";
import Popup from "reactjs-popup";
import { Link } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import "./popup.css";
import RestService from "./RestService";
import Loader from "../../component/Loader";

const PopupData = ({
  orderId,
  urlLink,
  symbol,
  orderType,
  getPageUpdatedStatus,
  mobile,
  web_token,
}) => (
  <Popup
    position="right center"
    key={symbol}
    trigger={<Link to={urlLink}>{symbol} </Link>}
    on="hover"
  >
    <Card
      symbol={symbol}
      orderId={orderId}
      orderType={orderType}
      getPageUpdatedStatus={getPageUpdatedStatus}
      mobile={mobile}
      web_token={web_token}
    />
  </Popup>
);

const Card = ({
  symbol,
  orderId,
  orderType,
  getPageUpdatedStatus,
  mobile,
  web_token,
}) => {
  const [loading, setLoading] = useState(false);
  const handleUpdateStatus = (symbol, orderId) => {
    setLoading(true);
    RestService.updateSymbolRecord(
      function () {
        setLoading(false);
        getPageUpdatedStatus(true);
      },
      mobile,
      web_token,
      orderId,
      symbol
    );
  };

  return (
    <div>
      {loading ? <Loader /> : ""}
      <button
        className="btn-sm btn-success"
        onClick={() => handleUpdateStatus(symbol, orderId)}
      >
        {parseInt(orderType) === 1 ? "Buy" : "Sell"}
      </button>
    </div>
  );
};

export default PopupData;
